import React, {  useEffect } from "react"
import TransitionLink from '../components/transitionLink';
import Layout from "../components/layout"
import SEO from "../components/seo"
import Logo from '../images/adobe-logo.inline.svg';
import { Helmet } from "react-helmet";
import createjs from 'preload-js';
import FooterPolicies from '../components/footer-policies';

interface Props {
    pageContext: any
    location: any
}
const NoteFromOurSponsorsPage = ({ pageContext, location }:Props) => {
    const { title, route, translation, settings, lang, langCode } = pageContext;
    const handleProgress = (data:any) => {
        // console.log(data.progress)
    }
    const handleComplete = (data:any) => {
        document.documentElement.classList.add('in');
    }
    useEffect(() => {
        const preload = new createjs.LoadQueue();
             preload.loadFile('/questions/green-c.png');
             preload.loadFile('/questions/light-blue-t-right.png');
             preload.loadFile('/questions/red-ring.png');
             preload.loadFile('/questions/teal-cube.png');
             preload.loadFile('/questions/mid-blue-c.png');
             preload.loadFile('/questions/pink-red-cube.png');
             preload.loadFile('/questions/teal-bowl.png');
             preload.loadFile('/questions/red-orange-c.png');
             preload.loadFile('/questions/mid-blue-t-left.png');
             preload.addEventListener("progress", handleProgress);
             preload.addEventListener("complete", handleComplete);
             preload.load();
    },[]);

    const FooterContent = () => (<>
        <TransitionLink className="link footer__link" to={ route }><span>{ translation.general.back }</span></TransitionLink>
        <FooterPolicies translation={translation}/>
        <a href={ translation.general.adobe_link } target="_blank" className="footer__logo" aria-label={ translation.general.go_to_adobe_website }><Logo/></a>
    </>);
    const content = translation.foreword_from_adobe.content.join('');
    return (
        <Layout pathname={ location.pathname}  socialLinks={settings.social_media} lang={ lang } route={route} translation={ translation } slug="foreword-from-adobe" footerContent={FooterContent}>
            <Helmet bodyAttributes={{ class: `note-from-our-authors-page lang-${lang}` }} />
            <SEO lang={langCode} title={ title } />
            <div className="note-from-authors">
                <div className="container">
                    <div className="note-from-authors__menu">
                        <TransitionLink className="note-from-authors__menu-item" to={ `${route}note-from-our-authors` }>{ translation.general.note_from_our_authors }</TransitionLink>
                        <div className="note-from-authors__menu-item active">{ translation.general.foreword_from_adobe }</div>
                    </div>
                    <div className="note-from-authors__content">
                        <div className="nfa__panel-1">
                            <img className="nfa__figure nfa__figure--green-c" src={'/questions/green-c.png'} alt="Green Circle" />
                            <img className="nfa__figure nfa__figure--light-blue-t" src={'/questions/light-blue-t-right.png'} alt="Light Blue Tube" />
                            <img className="nfa__figure nfa__figure--red-ring" src={'/questions/red-ring.png'} alt="Red Ring" />
                            <img className="nfa__figure nfa__figure--teal-cube" src={'/questions/teal-cube.png'} alt="Teal Cube" />
                            <img className="nfa__figure nfa__figure--mid-blue-circle" src={'/questions/mid-blue-c.png'} alt="Mid Blue Circle" />
                            <img className="nfa__figure nfa__figure--pink-red-cube" src={'/questions/pink-red-cube.png'} alt="Pink Red Cube" />
                            <img className="nfa__figure nfa__figure--teal-bowl" src={'/questions/teal-bowl.png'} alt="Teal Bowl" />
                            <img className="nfa__figure nfa__figure--red-orange-circle" src={'/questions/red-orange-c.png'} alt="Red Orange Cube" />
                            <img className="nfa__figure nfa__figure--mid-blue-tube" src={'/questions/mid-blue-t-left.png'} alt="Mid Blue Tube" />
                            <div className="note-from-authors__text">
                                <div dangerouslySetInnerHTML={{ __html: content }}></div>
                                <div className="note-from-authors__endofline"></div>
                            </div>
                        </div>                            
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default NoteFromOurSponsorsPage
